// Polyfill "NodeList.forEach"
if (window.NodeList && !NodeList.prototype.forEach) {
NodeList.prototype.forEach = Array.prototype.forEach;
}

// Polyfill "startsWith"
if (!String.prototype.startsWith) {
String.prototype.startsWith = function(searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
};
}

// Polyfill "closest"
if (window.Element && !Element.prototype.closest) {
Element.prototype.closest = 
function(s) {
    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
        i,
        el = this;
    do {
        i = matches.length;
        while (--i >= 0 && matches.item(i) !== el) {};
    } while ((i < 0) && (el = el.parentElement)); 
    return el;
};
}
