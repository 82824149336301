// Dependencies
import './polyfills.js'

// Enable gallery rotation
window.initGallery = () => {
  let productGalleries = document.querySelectorAll('.gallery')
  if ( productGalleries.length ) {
    console.log('GALLERY UP!!')
    
    // Reusable function to rotate all gallery images
    const rotateProductGallery = () => {
      productGalleries.forEach( galleryNode => {
  
        let showingImage = galleryNode.querySelector('img.showing')
        let nextImage = showingImage.nextElementSibling || galleryNode.querySelector('img')
  
        showingImage.classList.add('hide')
        showingImage.classList.remove('showing')
        nextImage.classList.remove('hide')
        nextImage.classList.add('showing')
      })
      setTimeout( rotateProductGallery, 5000 )
    }
    setTimeout( rotateProductGallery, 5000 )
  }
}

// Make "Modal" global
window.Modal = {

  // Show modal window by id
  show: id => {

    let modal = document.getElementById(id)
    modal.classList.add('show')

    // Only add "close" handler if not already set
    if ( !modal.classList.contains('ready') ) {

      // Mark as having event "hide' listeners
      modal.classList.add('ready')

      // Check if modal overlay should hide self
      if (modal.classList.contains('hide-modal')) {
        modal.addEventListener( 'click', e => {
          e.stopPropagation()
          if (e.target === modal) modal.classList.remove('show') 
        })
      }

      // Get modal's "hide" button if exists
      let modalx = modal.querySelector('.hide-modal')

      if (modalx ) {
        modalx.addEventListener( 'click', e => {
          modal.classList.remove('show')
        })
      }

    }
  },

  // Hide modal window by id
  hide: id => {
    let modal = document.getElementById(id)
    modal.classList.remove('show')
  }

}

// Init modals
document.querySelectorAll('[data-modal-trigger]').forEach( node => {
  node.addEventListener( 'click', function(e) {
    let modalId = this.getAttribute('data-modal-trigger')
    Modal.show(modalId)
  })
})

// Global util object.
// Used to be an ES6 module, but having a global util object is more effiecient (kb)
window.util = {}

// Handle in-house booking link clicks
window.handleInHouse = e => {
  if( !e.altKey && !e.ctrlKey && !e.metaKey && !e.shiftKey && document.querySelector('#booking_loader') ) {
    window.Modal.show('booking_loader')
  }
}

// Document Ready IE9+
util.docReady = fn => {
  if (document.readyState != 'loading'){
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

/**
 * Simple HTTP request function
 * @param {string} url 
 * @param {function} success 
 * @param {function} error 
 */
util.ajax = function( url, success, error = null, method = 'GET', params = null ) {

  let request = new XMLHttpRequest()
  request.open(method, url, true)

  //Send the proper header information along with the request
  if( method === 'POST' ) request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  
  request.onload = function() {

    // Success, so call success callback
    if (this.status >= 200 && this.status < 400) {
      success( this.response )

    // Response, but not ok, perform error callback if set
    } else {
      if ( error && (typeof error === 'function' ) ) {
        error( this.response )
      }
    }
  }
  
  // No response, perform error callback if set
  request.onerror = function() {
    if ( error && (typeof error === 'function' ) ) {
      error( this.response )
    }
  }
  
  // Dispatch
  request.send(params)
}

/**
 * Simple HTTP POST request function
 * @param {string} url 
 * @param {function} success 
 * @param {function} error 
 */
util.ajaxPost = function( url, params, success, error = null ) {
  this.ajax(url, success, error, 'POST', params)
}

/**
 * Simple HTTP request function for JSON responses
 * @param {string} url 
 * @param {function} success 
 * @param {function} error 
 */
util.ajaxJSON = function( url, success, error = null ) {
  this.ajax(url, res => { success(JSON.parse(res)) }, error)
}

/**
 * Deep object cloning function
 * @param {object} out 
 */
util.deepExtend = function(out) {
  out = out || {};

  for (let i = 1; i < arguments.length; i++) {
    let obj = arguments[i];

    if (!obj)
      continue;

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object'){
          if(obj[key] instanceof Array == true)
            out[key] = obj[key].slice(0);
          else
            out[key] = this.deepExtend(out[key], obj[key]);
        }
        else
          out[key] = obj[key];
      }
    }
  }
  return out;
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * @param  {[type]} func      Function to debounce
 * @param  {[type]} wait      Debounce time in ms
 * @param  {[type]} immediate Trigger on leading instead of trailing edge
 */
util.debounce = (func, wait, immediate) => {
	let timeout
	return () => {
		let context = this,
				args = arguments
		let later = function() {
			timeout = null
			if (!immediate) func.apply(context, args)
		}
		let callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}

/**
 * Parse url params (helper)
 * @param  {string} name Url param to retreive
 * @return {string}      Param value
 */
util.urlParam = name => {
	return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}

// Read a cookie
util.getCookie = name => {
	var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
	return v ? v[2] : null;
}

// Write a cookie
util.setCookie = (name, value, days) => {
	var d = new Date;
	d.setTime(d.getTime() + 24*60*60*1000*days);
	document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}
